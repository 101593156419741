import { useState, useEffect } from 'react';
import API from '../../@onocomm-system';
import {
	Container,
	Row,
	Col,
	Form,
	Button,
	Modal,
	Image,
} from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import { ModalAlert } from '../../Objects/Modal';
import Table from '../../Objects/Table';

const Default = () => {

	const [load, setLoad] = useState(true);
	const [lock, setLock] = useState(false);
	const [alert, setAlert] = useState([]);
	const [cartData, setCartData] = useState([]);
	const [addData, setAddData] = useState([]);
	const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

	useEffect(() => {
	
		const getData = async () => {
			
			try{
				//件数の取得
				const ref = await API.Cart.Query();
				
				//データセット
				setCartData(ref.items);
				
			}
			catch(err){
				console.log(err);
			}

			//表示開始
			setLoad(false);
			
		};
		
		getData();
		
		//eslint-disable-next-line react-hooks/exhaustive-deps
	},[]);

	useEffect(() => {

		const handleResize = () => {
			setIsMobile(window.innerWidth < 768);
		};

		handleResize();

		window.addEventListener('resize', handleResize);

		// Cleanup event listener on component unmount
		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, []);

	const handleCheck = async (event) => {
		
		//イベントキャンセル
		event.preventDefault();
		event.stopPropagation();

		//Formをロック
		setLock(true);
		
		try {
			
			//Formオブジェクトの取得
			const formObj = event.currentTarget;
			
			//Formオブジェクトの確認
			if(!formObj){
				throw new Error('An invalid error has occurred');
			}
			
			//Formのvalidationチェック
			if(formObj.checkValidity() === false){
				throw new Error('There was an error in the input item');
			}

			//発注指示のテキストデータを取得
			const item_data_text = formObj?.item_data?.value ?? '';

			//データの指定がない場合はエラー
			if(item_data_text === ''){
				throw new Error('データの指定がありません');
			}

			//データを改行で分割
			const item_data = item_data_text.replace(/ /g, '').split('\n');

			//オブジェクトデータの初期化
			const checkData = [];

			//1行ずつテキストデータを処理をしてカンマ区切りでデータを取得
			for(const item of item_data){
				const columns = item.split(',');
				const item_id = columns?.[0] ?? null;
				const piece = columns?.[1] ? columns?.[1] : null;
				const pre_order_request = columns?.[2] ? 1 : 0;
				if(!item_id){
					continue;
				}
				checkData.push({
					item_id,
					piece,
					pre_order_request,
				});
			}
			//console.log(checkData);

			if(!checkData.length){
				throw new Error('データ形式が正しくありません');
			}

			//JANコードの重複チェック
			const itemIds = checkData.map(item => item.item_id); // item_id の配列を取得
			const duplicateIds = itemIds.filter((id, index) => itemIds.indexOf(id) !== index); // 重複する item_id をフィルター

			if (duplicateIds.length > 0) {
				throw new Error('JANコードが重複しています:' + duplicateIds?.[0]);
			}

			//カート処理用のデータを作成
			const newAddData = [];
			
			//商品マスタとの照合
			for(const checkItem of checkData){
				const item_id = checkItem.item_id;
				const ref = await API.Item.Get(item_id);
				const item = ref.item;
				const original_piece = Number(checkItem.piece ?? (item.minimum_unit ?? 1));
				let piece = original_piece;
				const pre_order_request = checkItem.pre_order_request;
				if(Object.keys(item).length === 0){
					throw new Error('商品が見つかりません: ' + item_id);
				}
				if(Number(piece) < Number(item.minimum_unit)){
					piece = item.minimum_unit;
				}
				if(Number(piece) % Number(item.minimum_unit) !== 0){
					//throw new Error('数量は' + item.minimum_unit + 'の単位で指定してください。: ' + item_id);
					const minimumUnit = Number(item.minimum_unit);
					const roundedUpValue = Math.ceil(Number(piece) / minimumUnit) * minimumUnit;
				  	// 丸められた値を適用する
					piece = roundedUpValue;
				}
				//カート内の個数を取得
				const cart_piece = cartData.find(cartItem => cartItem.item_id === item_id)?.piece;
				newAddData.push({
					item_id : item_id,
					product_no : item.product_no,
					product_name : item.product_name,
					gousu : item.gousu,
					price : Number(item?.price ?? 0),
					unit_price : Number(item?.unit_price ?? 0),
					piece : Number(piece),
					original_piece : Number(original_piece ?? 0),
					cart_piece: Number(cart_piece ?? 0),
					added_piece : Number(piece) + Number(cart_piece ?? 0),
					minimum_unit : item.minimum_unit,
					category1_name : item.category1_name,
					category1_code : item.category1_code,
					category2_name : item.category2_name,
					category2_code : item.category2_code,
					genre_name : item.genre_name,
					genre_code : item.genre_code,
					catalog_no : item.catalog_no,
					keyword : item.keyword,
					sort : item.sort,
					boxed_num : item.boxed_num,
					url : item.url,
					pre_order_flag : item.pre_order_flag,
					pre_order_request : item?.pre_order_flag ? 1 : pre_order_request ? 1 : 0,
					stock_flag : item.stock_flag,
					message : '',
				});
			};
			//console.log(newAddData);

			setAddData(newAddData);

			/*
			for(const param of newAddData){
				//カートに追加
				const ref = await API.Cart.Add(param);
				//console.log(ref);
				//エラー確認
				if(ref.error){
					throw new Error('入力エラーがありました。(' + ref.error + ')');
				}
			}

			throw new Error('商品をカートに入れました。');
			*/
			
		}
		catch(err){
			setAlert([err.message]);
		}
		//ロック解除
		setLock(false);
	};	

	const handleSubmit = async () => {
		
		//Formをロック
		setLock(true);
		
		try {

			for(const item of addData){

				const param = {
					item_id : item.item_id,
					product_no : item.product_no,
					product_name : item.product_name,
					gousu : item.gousu,
					price : item.price,
					unit_price : item.unit_price,
					piece : item.piece,
					minimum_unit : item.minimum_unit,
					category1_name : item.category1_name,
					category1_code : item.category1_code,
					category2_name : item.category2_name,
					category2_code : item.category2_code,
					genre_name : item.genre_name,
					genre_code : item.genre_code,
					catalog_no : item.catalog_no,
					keyword : item.keyword,
					sort : item.sort,
					boxed_num : item.boxed_num,
					url : item.url,
					pre_order_flag : item.pre_order_flag,
					pre_order_request : item.pre_order_flag,
					stock_flag : item.stock_flag,
					message : '',
				};
				//console.log(param);
				
				//カートに追加
				const ref = await API.Cart.Add(param);
				//console.log(ref);
				//エラー確認
				if(ref.error){
					throw new Error('入力エラーがありました。(' + ref.error + ')');
				}
			}

			//初期化
			setAddData([]);
			
			throw new Error('商品をカートに入れました。');
		}
		catch(err){
			setAlert([err.message]);
		}
		//ロック解除
		setLock(false);

	};

	const handleClose = () => {
		if(alert?.[0] === '商品をカートに入れました。'){
			setAlert([]);
			window.location.href = '/cart';
		}
		setAlert([]);
		setAddData([]);
	};
	
	/*
	const formatPrice = (cell, row) => {
		return (
			<div className="text-right">
				{Number(cell ?? 0).toLocaleString()} 円
			</div>
		);
	};
	*/

	const formatPiece = (cell, row) => {
		return (
			<div className="text-right">
				{Number(cell ?? 0).toLocaleString()} 個
			</div>
		);
	};

	const formatCart = (cell, row) => {
		if(cell){
			return (
				<div className="text-right">
					{Number(cell ?? 0).toLocaleString()} 個
				</div>
			);
		}
		else{
			return (
				<div className="text-right">
					なし
				</div>
			);
		}
	};

	const columns = [
		{
			dataField: 'item_id',
			text: 'JANコード',
			sort: true,
			headerStyle:  { whiteSpace : 'nowrap' },
			style:  { whiteSpace : 'nowrap' },
		},
		/*
		{
			dataField: 'product_no',
			text: '品番',
			sort: true,
			headerStyle:  { whiteSpace : 'nowrap' },
			style:  { whiteSpace : 'nowrap' },
			sortFunc: (a, b, order, dataField, rowA, rowB) => {
				const numA_product_no = parseInt(rowA?.product_no ?? 0);
				const numB_product_no = parseInt(rowB?.product_no ?? 0);
				const numA_sort = parseInt(rowA?.sort ?? 0);
				const numB_sort = parseInt(rowB?.sort ?? 0);
				if (numA_product_no < numB_product_no) {
					return order === 'asc' ? -1 : 1;
				} else if (numA_product_no > numB_product_no) {
					return order === 'asc' ? 1 : -1;
				} else {
				  // product_noが同じ場合、sortフィールドによってソート
				  if (numA_sort < numB_sort) {
						return order === 'asc' ? -1 : 1;
				  } else if (numA_sort > numB_sort) {
						return order === 'asc' ? 1 : -1;
				  } else {
						return 0;
					}
				}
			},
		},
		*/
		{
			dataField: 'product_name',
			text: '品名',
			sort: true,
			headerStyle:  { whiteSpace : 'nowrap' },
			style:  { whiteSpace : 'nowrap' },
		},
		{
			dataField: 'gousu',
			text: '号数',
			sort: true,
			headerStyle:  { whiteSpace : 'nowrap' },
			style:  { whiteSpace : 'nowrap' },
		},
		{
			dataField: 'original_piece',
			text: '入力された個数',
			sort: true,
			headerClasses: 'text-right',
			formatter: formatPiece,
			headerStyle:  { whiteSpace : 'nowrap' },
			style:  { whiteSpace : 'nowrap' },
		},
		{
			dataField: 'piece',
			text: '変更後の個数',
			sort: true,
			headerClasses: 'text-right',
			formatter: formatPiece,
			headerStyle:  { whiteSpace : 'nowrap' },
			style:  { whiteSpace : 'nowrap' },
		},
		{
			dataField: 'cart_piece',
			text: '現在のカート内個数',
			sort: true,
			headerClasses: 'text-right',
			formatter: formatCart,
			headerStyle:  { whiteSpace : 'nowrap' },
			style:  { whiteSpace : 'nowrap' },
		},
		{
			dataField: 'minimum_unit',
			text: '最少出荷単位',
			sort: true,
			headerClasses: 'text-right',
			formatter: formatPiece,
			headerStyle:  { whiteSpace : 'nowrap' },
			style:  { whiteSpace : 'nowrap' },
		},
		/*
		{
			dataField: 'price',
			text: 'ﾒｰｶｰ希望本体価格',
			sort: true,
			headerClasses: 'text-right',
			formatter: formatPrice,
			headerStyle:  { whiteSpace : 'nowrap' },
			style:  { whiteSpace : 'nowrap' },
		},
		{
			dataField: 'added_piece',
			text: 'カートに入れたあとの個数',
			sort: true,
			headerClasses: 'text-right',
			formatter: formatPiece,
			headerStyle:  { whiteSpace : 'nowrap' },
			style:  { whiteSpace : 'nowrap' },
		},
		*/
		/* Ver.3.30
		{
			dataField: 'pre_order_flag',
			text: '注残',
			sort: true,
			headerStyle:  { whiteSpace : 'nowrap' },
			style:  { whiteSpace : 'nowrap' },
				options : {
					0: '○',
					1: '×',
				}
			}) : null,
			formatter: (cell) => cell ? '×' : '○',
		},
		*/
	];

	const mobileColumns = [
		{
			dataField: 'item_id',
			text: '詳細',
			sort: true,
			formatter: (cell, row) => {
				return (
					<table width="100%">
						<tbody>
						<tr>
							<td>JAN</td>
							<td>{row.item_id}</td>
						</tr>
						<tr>
							<td>品名</td>
							<td>{row.product_name}</td>
						</tr>
						<tr>
							<td>号数</td>
							<td>{row.gousu}</td>
						</tr>
						<tr>
							<td>入力された個数</td>
							<td>{row.original_piece} 個</td>
						</tr>
						<tr>
							<td>変更後の個数</td>
							<td>{row.piece} 個</td>
						</tr>
						<tr>
							<td>現在のカート内個数</td>
							<td>{row.cart_piece} 個</td>
						</tr>
						<tr>
							<td>最少出荷単位</td>
							<td>{row.minimum_unit} 個</td>
						</tr>
						{/* Ver.3.30
						<tr>
							<td>注残</td>
							<td>{row.pre_order_flag}</td>
						</tr>
						*/}
						{/*
						<tr>
							<td>カートに入れたあとの個数</td>
							<td>{row.added_piece}</td>
						</tr>
						<tr>
							<td>在庫</td>
							<td>{row.stock_flag}</td>
						</tr>
						<tr>
							<td>品番</td>
							<td>{row.product_no}</td>
						</tr>
						<tr>
							<td>価格</td>
							<td>{Number(row.price ?? 0).toLocaleString()} 円</td>
						</tr>
						*/}
						</tbody>
					</table>
				);
			},
			headerStyle:  { display: 'none' },
		},
		{
			dataField: 'product_no',
			hidden: true,
			text: '品番',
			headerStyle:  { display: 'none' },
			sortFunc: (a, b, order, dataField, rowA, rowB) => {
				const numA_product_no = parseInt(rowA?.product_no ?? 0);
				const numB_product_no = parseInt(rowB?.product_no ?? 0);
				const numA_sort = parseInt(rowA?.sort ?? 0);
				const numB_sort = parseInt(rowB?.sort ?? 0);
				if (numA_product_no < numB_product_no) {
					return order === 'asc' ? -1 : 1;
				} else if (numA_product_no > numB_product_no) {
					return order === 'asc' ? 1 : -1;
				} else {
				  // product_noが同じ場合、sortフィールドによってソート
				  if (numA_sort < numB_sort) {
						return order === 'asc' ? -1 : 1;
				  } else if (numA_sort > numB_sort) {
						return order === 'asc' ? 1 : -1;
				  } else {
						return 0;
					}
				}
			},
		},
	];

	const defaultSorted = [
		{
			dataField: 'product_no',
			order: 'asc',
		}
	];

	const options = {
		sizePerPageList: [
			{ text: '10', value: 10},
		],
	};

	const mobileOptions = {
		sizePerPageList: [
			{ text: '1', value: 1},
		],
	};

	if(load){
		return (
			<Row style={{ marginTop: 200, marginBottom: 200 }}>
				<Col sm={12} className="text-center">
					<Image src="/img/loading.gif" />
				</Col>
			</Row>
		);
	}
	else{
		return (
			<Container style={{ marginTop: 50, marginBottom: 200 }}>
				<ModalAlert data={alert} onClick={handleClose} />
				<Modal
					show={addData?.length ? true : false}
					onHide={handleClose}
					dialogClassName={addData.filter(x=>x.original_piece !== x.piece).length ? 'custom-modal' : null}
					centered
				>
					{addData.filter(x=>x.original_piece !== x.piece).length ? 
						<>
							<Modal.Header closeButton>
								<Modal.Title>
									「発注数量変更一覧」※発注単位と異なるため数量が変更となっております。
									このままカートにいれますか？
								</Modal.Title>
							</Modal.Header>
							<Modal.Body>
								<Table keyField={'item_id'} data={addData.filter(x=>x.original_piece !== x.piece)} columns={isMobile ? mobileColumns : columns} checkbox={false} defaultSorted={defaultSorted} options={isMobile ? mobileOptions : options} />
								<p>合計で {addData.length} 件の商品がカートに追加されます。</p>
								<Button variant="primary" onClick={handleSubmit} disabled={lock}>カートに入れる</Button>
							</Modal.Body>
							<Modal.Footer>
								<Button variant="secondary" onClick={handleClose}>
									閉じる
								</Button>
							</Modal.Footer>
						</>
					:
						<>
							<Modal.Header closeButton>
								<Modal.Title>
									カートにいれますか？
								</Modal.Title>
							</Modal.Header>
							<Modal.Body>
								<p>合計で {addData.length} 件の商品がカートに追加されます。</p>
								<Button variant="primary" onClick={handleSubmit} disabled={lock}>カートに入れる</Button>
							</Modal.Body>
							<Modal.Footer>
								<Button variant="secondary" onClick={handleClose}>
									閉じる
								</Button>
							</Modal.Footer>
						</>
					}
				</Modal>
				<Form name="form" onSubmit={handleCheck}>

					<Row>
						<Col sm={6}>
							<p><big>一括発注</big></p>
						</Col>
						<Col sm={12}>
							{/* Ver.3.30
								<p>
									半角カンマ区切りでJANコード、個数、注残を指定してください。個数と注残は省略可能です。
									1行に1商品ずつ指定が出来ます。複数の商品を指定する場合は改行してください。
									{'例：JANコード[[,個数（指定がない場合は最少出荷単位）],注残（0か1か）]'}
								</p>
							*/}
							<p>
								半角カンマ区切りでJANコード、個数を指定してください。個数は省略可能です。
								1行に1商品ずつ指定が出来ます。複数の商品を指定する場合は改行してください。
								{'例：JANコード[,個数（指定がない場合は最少出荷単位）]'}
							</p>
						</Col>
					</Row>
					{/* Ver.3.30
						<textarea name="item_data" className="form-control" placeholder={'4953873000829,5,0'} rows={15} required={true}>
					*/}
					<textarea name="item_data" className="form-control" placeholder={'4953873000829,5'} rows={15} disabled={lock} required={true}>
					</textarea>				
					<Row style={{ marginTop: 10 }}>
						<Col>
								<Button variant="dark" type="submit" block="true" disabled={lock} >カートに入れる</Button>
						</Col>
					</Row>
				</Form>
			</Container>
		);
	}
};

export default withRouter(Default);
